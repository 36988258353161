// Types
import { GetStream_stream } from 'api/streams/types/GetStream';
import {
  GetStoreBySlug_store_hashtags,
  GetStoreBySlug_store_nextStream,
} from 'api/store/types/GetStoreBySlug';
import { MyStreams_myStreams_entities } from 'api/streams/types/MyStreams';
import { AmaRequest_amaRequest_store_nextStream } from 'api/ama/types/AmaRequest';
import { GetAllHomePageData_getHomePageData_merch } from 'api/homePage/types/GetAllHomePageData';
import { GetUpcomingStreams_streams_entities } from 'api/streams/types/GetUpcomingStreams';
import { GetStreams_streamsV2_entities } from 'api/streams/types/GetStreams';
import { GetMerchProducts_getMerchProducts_entities } from 'api/merch/types/GetMerchProducts';
import { MyOutcomingStreamOrders_myOutcomingStreamOrders_entities_stream } from 'api/streams/types/MyOutcomingStreamOrders';
import { HomepageSearch_streamsV2_entities } from 'api/search/types/HomepageSearch';
import { GetAllMerchProducts_getMerchProducts_entities } from 'api/allMerch/types/GetAllMerchProducts';
import { GetMemorabiliaBySlug_getMemorabiliaBySlug } from 'api/memorabilia/types/GetMemorabiliaBySlug';
import { GetExperienceBySlug_getExperienceBySlug } from 'api/experiences/types/GetExperienceBySlug';
import { GetMerchMinimalInfo_getMerchProducts_entities } from 'api/merch/types/GetMerchMinimalInfo';
// Constants
import { MERCH, TAG } from 'constants/routes';

export type StreamComputeParams =
  | GetStream_stream
  | GetStoreBySlug_store_nextStream
  | MyStreams_myStreams_entities
  | GetUpcomingStreams_streams_entities
  | GetStreams_streamsV2_entities
  | AmaRequest_amaRequest_store_nextStream
  | MyOutcomingStreamOrders_myOutcomingStreamOrders_entities_stream
  | HomepageSearch_streamsV2_entities
  | null
  | undefined;

export const getStreamPath = (
  stream: StreamComputeParams,
  path:
    | 'streams'
    | 'start-stream'
    | 'cohost-stream'
    | 'watch-stream'
    | 'moderate-stream'
): string => {
  const storeSlug = stream?.store?.slug;
  const streamSlug = stream?.slug;

  if (!storeSlug || !streamSlug) {
    return '/';
  }

  return `/${storeSlug}/${path}/${streamSlug}`;
};

export const computeOrderStreamPath = (stream: StreamComputeParams): string => {
  return getStreamPath(stream, 'streams');
};

export const computeStartStreamPath = (stream: StreamComputeParams): string => {
  return getStreamPath(stream, 'start-stream');
};

export const computeCohostStartStreamPath = (
  stream: StreamComputeParams
): string => {
  return getStreamPath(stream, 'cohost-stream');
};

export const computeWatchStreamPath = (stream: StreamComputeParams): string => {
  return getStreamPath(stream, 'watch-stream');
};

export const computeMerchProductPath = (
  merchItem:
    | GetAllHomePageData_getHomePageData_merch
    | GetMerchProducts_getMerchProducts_entities
    | GetAllMerchProducts_getMerchProducts_entities
    | GetMerchMinimalInfo_getMerchProducts_entities
): string => {
  return `/${merchItem.store?.slug}${MERCH}/${merchItem.slug}`;
};

export const computeMemorabiliaProductPath = (
  memorabiliaItem: GetMemorabiliaBySlug_getMemorabiliaBySlug | undefined
): string => {
  if (!memorabiliaItem) return '/';

  return `/${memorabiliaItem.store?.slug}/memorabilia/${memorabiliaItem.slug}`;
};

export const computeExperiencePath = (
  experience: GetExperienceBySlug_getExperienceBySlug | undefined
): string => {
  if (!experience) return '/';

  return `/${experience.store?.slug}/experience/${experience.slug}`;
};

export const computeTagPath = (tag: GetStoreBySlug_store_hashtags): string => {
  return `${TAG}/${tag.name.toLowerCase()}`;
};

export const isActiveRoute = (
  routePathname: string,
  routeUrl: string,
  itemQuery: string | null = null,
  query: string | string[] | null = null
): boolean => {
  if (query && itemQuery) {
    return query.includes(itemQuery);
  } else {
    return routePathname === routeUrl;
  }
};

export const getEnvLink = (): string => {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : process.env.NEXT_PUBLIC_BASE_PATH || '';
};
