import { NextLink, Operation, fromPromise } from '@apollo/client';
// Lib
import { logOut as googleLogout } from 'lib/google-sdk';
import { logOut as facebookLogout } from 'lib/facebook-sdk';
// Types
import { SignOut } from 'api/auth/types/SignOut';
import { RefreshTokens } from 'api/auth/types/RefreshTokens';
// Helpers
import {
  setTokenToCookies,
  getTokenFromCookies,
  clearTokenFromCookies,
  setGuestTokenToCookies,
  getGuestTokenFromCookies,
  clearGuestTokenFromCookies,
} from 'helpers/cookies';
import { isDevelopmentEnv } from 'helpers/env';

export type FetchResponse<T> = {
  data: T | null;
  errors?: Error[];
};

export let isRefreshing = false;
export let pendingRequests: (() => void)[] = [];

export const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

export const addPendingRequest = (pendingRequest: () => void) => {
  pendingRequests.push(pendingRequest);
};

export const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export const clearPendingRequests = () => {
  pendingRequests = [];
};

export const getIfHaveTokenError = (message: string) => {
  return [
    'Unauthorized',
    // these 2 error are thown for getStreamBySlug when token expires
    'Not Authorized to access rtmpIngestURL on type Stream',
    'Not Authorized to access rtmpIngestKey on type Stream',
    'You are not authorized to make this call.',
    'token is not defined',
    'Forbidden resource',
    'Forbidden',
  ]
    .map((i) => i.toLowerCase())
    .includes(message.toLowerCase());
};

const getIfHaveAccessError = (message: string) => {
  return [
    'Refresh token is stale',
    'Invalid token',
    'JWT token has expired or invalid',
    'Your account has been deactivated',
  ]
    .map((i) => i.toLowerCase())
    .includes(message.toLowerCase());
};

export const clearAuthData = () => {
  setIsRefreshing(false);
  clearPendingRequests();

  clearTokenFromCookies();
  clearGuestTokenFromCookies();

  /**
   * Trigger "fbSdk.logOut()" everywhere except dev env to avoid the error
   * "The method FB.getLoginStatus can no longer be called from http pages."
   */
  if (!isDevelopmentEnv) {
    facebookLogout().then();
  }

  googleLogout().then();

  window.location.reload();
};

const getLocalStorageTokens = () => {
  const localStorageToken = getTokenFromCookies();
  const localStorageGuestToken = getGuestTokenFromCookies();
  const accessToken =
    localStorageToken?.accessToken || localStorageGuestToken?.accessToken;
  const refreshToken =
    localStorageToken?.refreshToken || localStorageGuestToken?.refreshToken;

  return {
    localStorageToken,
    localStorageGuestToken,
    accessToken,
    refreshToken,
  };
};

const handleFetch = async <T = any>(
  query: string
): Promise<FetchResponse<T>> => {
  const { accessToken, refreshToken } = getLocalStorageTokens();

  return fetch(process.env.NEXT_PUBLIC_API_HOST || '', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken || ''}`,
    },
    body: JSON.stringify({
      query,
      variables: { input: { refreshToken } },
    }),
  }).then((res) => res.json());
};

export const refreshTokenMutation = `
  mutation RefreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const refreshUserToken = async () => {
  const { localStorageToken, localStorageGuestToken } = getLocalStorageTokens();

  try {
    const { data, errors } = await handleFetch<RefreshTokens>(
      refreshTokenMutation
    );

    const accessToken = data?.refreshTokens.accessToken;
    const refreshToken = data?.refreshTokens.refreshToken;

    if (accessToken && refreshToken) {
      if (localStorageToken) {
        setTokenToCookies({
          ...localStorageToken,
          accessToken,
          refreshToken,
        });
      } else if (localStorageGuestToken) {
        setGuestTokenToCookies({
          ...localStorageGuestToken,
          accessToken,
          refreshToken,
        });
      }
    }

    if (errors) {
      clearAuthData();
    }
  } catch (error) {
    clearAuthData();
  }
};

export const signOutMutation = `
  mutation SignOut($input: SignOutInput!) {
    singOut(input: $input)
  }
`;

export const signOut = async () => {
  try {
    await handleFetch<SignOut>(signOutMutation);
  } catch (error) {
    console.log('signOut error', error);
  }
};

export const onAuthError = async (errorMessages: string[]) => {
  const haveTokenError = errorMessages.some(getIfHaveTokenError);
  const haveAccessError = errorMessages.some(getIfHaveAccessError);

  const { refreshToken, accessToken } = getLocalStorageTokens();

  if (!refreshToken?.length && accessToken?.length && haveTokenError) {
    clearAuthData();
  } else if (refreshToken?.length) {
    if (haveAccessError) {
      await signOut();
      clearAuthData();
    }

    if (haveTokenError) {
      await refreshUserToken();
    }
  }
};

export const handleAuthError = ({
  operation,
  forward,
  errorMessages,
}: {
  errorMessages: string[];
  operation: Operation;
  forward: NextLink;
}) => {
  if (!isRefreshing) {
    setIsRefreshing(true);

    return fromPromise(
      onAuthError(errorMessages).catch(() => {
        setIsRefreshing(false);

        return forward(operation);
      })
    ).flatMap(() => {
      resolvePendingRequests();
      setIsRefreshing(false);

      return forward(operation);
    });
  } else {
    return fromPromise(
      new Promise((resolve) => {
        addPendingRequest(() => resolve(true));
      })
    ).flatMap(() => {
      return forward(operation);
    });
  }
};
